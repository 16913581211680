import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import styled from "styled-components"
import { Formik, Form, Field } from "formik"

import usePageTitle from "mill/hooks/usePageTitle"
import useTrackPage from "mill/hooks/useTrackPage"
import useCampaignCollectionOptions from "mill/hooks/useCampaignCollectionOptions"

import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Button from "shared/components/Button"
import Select from "shared/components/FormSelect"
import { adminCampaignAddLearnersPath } from "mill/routes/RouteNames"
import { FormikTextField as TextField } from "mill/components/TextField"
import { FormikTextAreaField as TextArea } from "mill/components/TextArea"
import { FormikCheckbox as Checkbox } from "shared/components/Checkbox"
import { Container, PanelImage, Well, Hr } from "mill/utils/shared/core"
import { HelpText } from "mill/utils/sharedStyles"
import Label from "mill/components/Label"
import FormGroup from "mill/components/FormGroup"
import campaignWizard from "mill/containers/Admin/CampaignWizard"
import { CampaignNameSchema } from "mill/utils/schemas"

import FETCH_CAMPAIGNS from "mill/graphql/FetchCampaigns"
import CREATE_CAMPAIGN from "mill/graphql/CreateCampaign"

import senecaPrepareForLaunch from "./seneca_prepare_for_launch.png"

const MechanismRibbon = styled.div`
  background-color: ${props =>
    props.burst ? props.theme.colors.tertiary : props.theme.colors.primary};
  color: white;
  text-align: center;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 4rem;
  font-weight: bold;
`

const InputTitleContainer = styled.div`
  input {
    min-width: 56rem;
  }
`

const DescriptionHelpText = () => {
  return (
    <HelpText>
      This will appear to learners in the campaign invitation.{" "}
      <a
        href="https://www.yarno.com.au/support/category/yarno-campaigns/campaign-descriptions"
        target="_blank"
        rel="noreferrer">
        Read more about campaign descriptions here.
      </a>
    </HelpText>
  )
}

const NewCampaign = props => {
  let navigate = useNavigate()
  const { mechanism } = useParams()
  const [showWell, setShowWell] = useState(null)
  const { collectionOptions, createCampaignCollection } =
    useCampaignCollectionOptions()

  const [createCampaign, { loading }] = useMutation(CREATE_CAMPAIGN, {
    onCompleted: ({ createCampaign }) => {
      navigate(adminCampaignAddLearnersPath(createCampaign.campaign.id))
    }
  })

  const { data, loading: campaignsLoading } = useQuery(FETCH_CAMPAIGNS, {
    variables: {
      mechanisms: [mechanism]
    }
  })

  usePageTitle("New Campaign")
  useTrackPage("New Campaign", { mechanism: mechanism })

  const renderCopySelections = (campaigns, values) => {
    const selectedCampaignID = values.copy.campaignId
    const options = campaigns.map(c => ({
      value: c.node.id,
      label: c.node.name
    }))
    const selectedCampaign =
      selectedCampaignID && campaigns.find(c => c.node.id == selectedCampaignID)

    if (selectedCampaign) {
      return (
        <FormGroup marginTop={1}>
          <Field
            name={"copy.campaignId"}
            component={Select}
            options={options}
            type="select"
          />
          <Label
            style={{ display: "block", marginTop: "2rem" }}
            title={`Please choose what you'd like to copy from the ${selectedCampaign.node.name} campaign.`}
          />
        </FormGroup>
      )
    }
    return (
      <Field name={"copy.campaignId"} component={Select} options={options} />
    )
  }

  const renderCopyOptions = values => {
    const selectedCampaignId = values.copy.campaignId
    if (selectedCampaignId !== "") {
      return (
        <React.Fragment>
          <Field
            withFieldset
            inline
            name={"copy.learners"}
            data-qa="copy.learners"
            checkboxLabel
            component={Checkbox}
            label="Learners"
            type="checkbox"
          />

          <Field
            withFieldset
            inline
            name={"copy.questions"}
            data-qa="copy.questions"
            component={Checkbox}
            label="Campaign questions"
            type="checkbox"
          />

          {values.mechanism == "embed" && (
            <Field
              withFieldset
              inline
              name={"copy.primers"}
              data-qa="copy.primers"
              component={Checkbox}
              label="Primer questions"
              type="checkbox"
            />
          )}

          <Field
            withFieldset
            inline
            name={"copy.confidenceSurvey"}
            data-qa="copy.confidenceSurvey"
            component={Checkbox}
            label="Confidence survey"
            type="checkbox"
          />

          <Field
            withFieldset
            inline
            name={"copy.settings"}
            data-qa="copy.settings"
            id={"copy.settings"}
            component={Checkbox}
            type="checkbox"
            label="Campaign settings"
          />
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <MechanismRibbon burst={mechanism == "burst"}>
        You are creating {mechanism == "burst" ? "a" : "an"} {mechanism}{" "}
        campaign.
      </MechanismRibbon>

      <Container formWidth style={{ marginTop: "2rem" }}>
        <Heading level={1}>Create new {mechanism} campaign</Heading>
      </Container>

      <Container formWidth>
        <Formik
          initialValues={{
            name: "",
            description: "",
            mechanism,
            collections: [],
            copy: {
              campaignId: "",
              learners: false,
              questions: false,
              primers: false,
              settings: false,
              confidenceSurvey: false
            }
          }}
          validationSchema={CampaignNameSchema}
          onSubmit={(values, actions) => {
            createCampaign({
              variables: {
                attributes: {
                  copy: values.copy,
                  name: values.name,
                  mechanism: values.mechanism,
                  collectionIds: values.collections,
                  description: values.description
                }
              }
            })
            actions.setSubmitting(false)
          }}>
          {({
            values,
            isSubmitting,
            initialValues,
            setValues,
            setFieldValue
          }) => {
            const handleCreateCollection = collectionName => {
              createCampaignCollection({
                variables: {
                  name: collectionName
                }
              }).then(
                ({
                  data: {
                    createCampaignCollection: { campaignCollection }
                  }
                }) => {
                  setFieldValue("collections", [
                    ...values.collections,
                    campaignCollection.id
                  ])
                }
              )
            }

            return (
              <Form>
                <InputTitleContainer>
                  <Field
                    withFieldset
                    fullWidth
                    name={"name"}
                    type="text"
                    data-qa="campaign-title"
                    component={TextField}
                    label={`Let's give the new ${mechanism} campaign a title`}
                    HelpComponent={() => (
                      <HelpText>
                        This will be displayed to all learners as "
                        <em>the 'title' campaign</em>".
                      </HelpText>
                    )}
                    placeholder="Something catchy..."
                  />
                </InputTitleContainer>

                <Field
                  withFieldset
                  fullWidth
                  name={"description"}
                  type="textarea"
                  data-qa="campaign-description"
                  component={TextArea}
                  label="Campaign description (optional)"
                  HelpComponent={DescriptionHelpText}
                  placeholder="This campaign will level you up in..."
                  style={{ minHeight: "8rem" }}
                />

                <Field
                  allowCreate
                  withFieldset
                  isMulti
                  isClearable
                  name="collections"
                  type="select"
                  component={Select}
                  label={"Collections (optional)"}
                  placeholder="Example collection"
                  helpText="These are useful when categorising campaigns."
                  options={collectionOptions}
                  onCreateOption={handleCreateCollection}
                  promptTextCreator={label => `Create collection ${label}`}
                  noOptionsMessage={() => "No collections found"}
                />

                {!campaignsLoading && data.campaigns.edges.length > 0 && (
                  <div style={{ marginTop: "1.5rem" }}>
                    <Label title="Would you like to copy any content from an existing campaign?" />
                    <div style={{ flex: 1, flexDirection: "row" }}>
                      <Box paddingRight="small" display="inline-block">
                        <Button
                          label={"Yes please"}
                          data-qa="copy-from-campaign"
                          type={"button"}
                          color="primary"
                          variant={showWell ? "filled" : "hollow"}
                          onClick={() => setShowWell(true)}
                        />
                      </Box>
                      <Button
                        label={"No thanks, this is a brand new campaign"}
                        data-qa="new-campaign"
                        type={"button"}
                        color="primary"
                        variant={showWell === false ? "filled" : "hollow"}
                        onClick={() => {
                          setValues({
                            ...initialValues,
                            name: values.name,
                            description: values.description,
                            collections: values.collections
                          })
                          setShowWell(false)
                        }}
                      />
                    </div>

                    {showWell && (
                      <Well className="pb3" data-qa="copy-campaign-select">
                        <Label
                          name={"copy.campaign"}
                          title="Great, which campaign?">
                          Whilst you can only pick one campaign below, you can
                          add learners and questions from further campaigns a
                          little later.
                        </Label>
                        {renderCopySelections(data.campaigns.edges, values)}
                        {renderCopyOptions(values)}
                      </Well>
                    )}
                  </div>
                )}
                <Hr />

                <Box
                  display={{ tablet: "flex" }}
                  alignItems="center"
                  justifyContent="flex-end"
                  paddingTop="medium"
                  paddingBottom="medium">
                  <Box
                    paddingRight={{ tablet: "medium" }}
                    paddingBottom={{ mobile: "small", tablet: "none" }}>
                    <Text align={{ tablet: "right" }}>
                      <em>
                        Next we'll add learners to the {mechanism} campaign. You
                        can always return to the campaign screen in the future
                        to make changes.
                      </em>
                    </Text>
                  </Box>
                  <Button
                    type="submit"
                    arrow
                    label={"Save & next"}
                    data-qa="next-step"
                    size="large"
                    color="secondary"
                    disabled={isSubmitting || loading}
                  />
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Container>

      <PanelImage
        className={"mt3"}
        background={senecaPrepareForLaunch}
        height={"400px"}
      />
    </React.Fragment>
  )
}
export default campaignWizard(NewCampaign, "campaign")
